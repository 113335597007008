import { Injectable, Signal, WritableSignal, effect, signal } from '@angular/core';
import {
    AvailableTranslation,
    TranslateRequest,
    TranslationApiService,
    TranslationService,
} from 'app/api';
import { ServerSentEventService } from 'app/services/sse/server-sent-event.service';
import { StorageService } from 'app/services/storage/storage.service';
import { AlertService } from '../alert/alert.service';
import { Observable } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';

@Injectable({
    providedIn: 'root',
})
export class TranslationTabService {
    private _ongoingTranslations: WritableSignal<OngoingTranslation[]> = signal([]);

    public get ongoingTranslations(): Signal<OngoingTranslation[]> {
        return this._ongoingTranslations.asReadonly();
    }

    constructor(
        private sseService: ServerSentEventService,
        private _aiTranslatorService: TranslationService,
        private translationService: TranslationApiService,
        private storageService: StorageService,
        private alertService: AlertService,
        private translocoService: TranslocoService,
        private orgFilterService: OrganisationFilterService
    ) {
        effect(
            () => {
                if (this.sseService.message()) {
                    const dataObject: any = JSON.parse(this.sseService.message());

                    this.setOngoingTranslationStatus(
                        dataObject.bpid,
                        dataObject.target_lang,
                        dataObject.status
                    );
                }
            },
            {
                allowSignalWrites: true,
            }
        );
    }

    getAvailableTranslations(
        bpId: string,
        taskId?: string
    ): Observable<Array<AvailableTranslation>> {
        return this.translationService.backendApiTranslationsGet(bpId, taskId);
    }

    async translateProcess(bpId: string, selectedLanguage: string): Promise<void> {
        const data: TranslateRequest = {
            bpid: bpId,
            target_lang: selectedLanguage,
            client_id: this.storageService.getClientId(),
            original_lang: this.translocoService.getActiveLang(),
            organisation_id: this.orgFilterService.selectedOrganisation(),
        };

        this.addOngoingTranslation(data);

        await this._aiTranslatorService.translate(data).toPromise();
    }

    private addOngoingTranslation(translateRequest: TranslateRequest): void {
        this._ongoingTranslations.update((ots: OngoingTranslation[]) => {
            ots.push({
                ...translateRequest,
                status: 'in_progress',
            });
            return [...ots];
        });
    }

    private setOngoingTranslationStatus(
        bpId: string,
        selectedLanguage: string,
        status: string
    ): void {
        if (status === 'complete') {
            this.alertService.successAlert(
                'languages',
                'translationCompleted',
                'translationCompletedTitle'
            );
        }

        this._ongoingTranslations.update((ots: OngoingTranslation[]) => {
            const ot = ots.find(ot => ot.bpid === bpId && ot.target_lang === selectedLanguage);
            if (ot) {
                ot.status = status;
            }
            return [...ots];
        });
    }
}

export interface OngoingTranslation {
    bpid: string;
    target_lang: string;
    client_id: string;
    status: string;
}

/**
 * {
    "request_id": "426a3a0a-fa0c-4af7-a296-e34afa36a2b5",
    "target_lang": "fr",
    "error_message": null,
    "status": "complete",
    "bpid": "1b147d0a-0e0b-4bda-a64d-decf5041ec6d"
}
 */
