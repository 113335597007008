import {
    Inject,
    Injectable,
    Injector,
    OnDestroy,
    WritableSignal,
    inject,
    signal,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BusinessProcess, BusinessProcessTask, System, TasksService } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { TranslationTabService } from 'app/core/translation-tab/translation-tab.service';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { SysService } from 'app/modules/org-sys-area/services/sys.service';
import { Observable, Subject, of, takeUntil } from 'rxjs';
import { FrontendAppConfiguration } from '../configuration.services';

/**
 * BusinessProcessTaskService contains the core functions to maintain BusinessProcessTasks.
 * That includes get any BP by id, get uuid for new item, save BP and delete BP by id.
 */
@Injectable({
    providedIn: 'root',
})
export class BusinessProcessTaskService implements OnDestroy {
    protected _destroy: Subject<void> = new Subject();

    model: WritableSignal<BusinessProcess> = signal<BusinessProcess>(null);
    _model: BusinessProcess;

    selectedId: WritableSignal<string> = signal(null);

    hasActiveTranslation: WritableSignal<boolean> = signal(false);

    private replacableId: string = 'clientId';

    protected _alertService = inject(AlertService);

    scope = 'bp';

    private sysService: SysService;

    constructor(
        protected apiService: TasksService,
        protected saveButtonService: SaveButtonService,
        protected translationTabService: TranslationTabService,
        protected translocoService: TranslocoService,
        protected injector: Injector
    ) {
        this.sysService = this.injector.get(SysService);
    }

    ngOnDestroy(): void {}

    public replaceSystemIdWithSystemTitle(tasks: BusinessProcessTask[]): BusinessProcessTask[] {
        return tasks.map((task: BusinessProcessTask) => {
            if (task.data.action.system) {
                const system = this.findSystemByIdDeeply(
                    task.data.action.system,
                    this.sysService.state()
                );
                if (system) {
                    task.data.action.system = system.title;
                }
            }
            return task;
        });
    }
    findSystemByIdDeeply(id: string, systems: System[]): System | null {
        for (let index = 0; index < systems.length; index++) {
            const system = systems[index];
            if (system.id === id) {
                return system;
            } else if (system.children) {
                const found = this.findSystemByIdDeeply(id, system.children);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    }

    public showAlert(alertMessage: string): void {
        this._alertService.successAlert(this.scope, alertMessage);
    }

    // #region API calls
    /**
     * Gets the BusinessProcess from the BE and sets it as model.
     * @param id Identifier (uuid) of the BusinessProcess
     * @returns
     */
    async getBusinessProcessById(id: string): Promise<void> {
        const availableTranslations = await this.translationTabService
            .getAvailableTranslations(id)
            .toPromise();
        const language = this.translocoService.getActiveLang();

        const availableTranslation = availableTranslations.find(t => t.lang === language);
        if (availableTranslation && availableTranslation.status === 'ACTIVE') {
            this.hasActiveTranslation.set(true);
        } else {
            this.hasActiveTranslation.set(false);
            this.model.set(null);
            this._alertService.errorAlert('languages', 'error.description', 'error.wtitle');
            return;
        }

        const wbs = this.getWbs(id);
        let hasWbsChanged = false;

        if (id) {
            let model = await this.apiService.getBusinessProcessById(id).toPromise();

            // If a task is not translated, the in/outputs and files are null, hence it is needed to be made to arrays
            model.tasks?.forEach(task => {
                if (task.data.input === null) {
                    task.data.input = [];
                }
                if (task.data.files === null) {
                    task.data.files = [];
                }
                if (task.data.output === null) {
                    task.data.output = [];
                }

                if (wbs) {
                    let no = task.data.action.no.split('.');
                    if (wbs !== no.slice(0, no.length - 1).join('.')) {
                        task.data.action.no = `${wbs}.${no[no.length - 1]}`;
                        hasWbsChanged = true;
                    }
                }
            });

            this.model.set(model);

            this.saveButtonService.hasUnsavedChanges = false;
            this.afterGotNewModel();

            if (hasWbsChanged) {
                this._alertService.infoAlert('bpTask', 'pleaseSave', 'wbsChanged');
                this.saveButtonService.hasUnsavedChanges = true;
            }
        }
    }

    afterGotNewModel(): void {}
    getWbs(id: string): string {
        throw new Error('Method not implemented.');
    }

    getModel() {
        return this.model();
    }

    /**
     * Sends the BusinessProcess in the model to the BE.
     * @returns
     */
    saveBusinessProcess(successMessage: string): Observable<any> {
        this.model().tasks.forEach(t => {
            if (t.id === 'new') {
                t.id = undefined;
            }
        });
        return of(
            this.apiService
                .updateBusinessProcess(this.replacableId, this.model())
                .pipe(takeUntil(this._destroy))
                .subscribe(bp => {
                    this.model.set({ ...bp });

                    this.showAlert(successMessage);
                    this.saveButtonService.hasUnsavedChanges = false;
                    this.handleAfterSave();
                    this.saveButtonService.setSaveFinished();
                })
        );
    }

    /**
     * Deletes a task by id from the BusinessProcess.
     * @param taskId Identifier (uuid) of the Task
     * @returns
     */
    deleteById(taskId: string): Observable<any> {
        let index = this.model().tasks.indexOf(
            this.model().tasks.find((bpTask: BusinessProcessTask) => bpTask.id === taskId)
        );

        if (index > -1) {
            const bp = { ...this.model() };

            bp.tasks.splice(index, 1);
            bp.tasks = bp.tasks.slice();
            this.model.set(bp);

            if (taskId === 'new') {
                setTimeout(() => {
                    this.showAlert('businessProcessDeletedSuccessMessage');
                });
                this.handleAfterDelete();
                return;
            }

            return of(
                this.apiService
                    .deleteBusinessProcessTask(this.replacableId, this.model().id, taskId)
                    .pipe(takeUntil(this._destroy))
                    .subscribe(() => {
                        this.showAlert('businessProcessDeletedSuccessMessage');
                        this.handleAfterDelete();
                    })
            );
        }

        return;
    }
    // #endregion

    protected handleAfterSave(): void {}
    protected handleAfterDelete(): void {}
}
