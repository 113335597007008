import { Inject, Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { TranslocoService } from '@ngneat/transloco';
import { ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { SaveButtonService } from 'app/core/save-button/save-button.service';
import { APP_CONFIGURATION_TOKEN } from 'app/injection-token';
import { Observable, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { FrontendAppConfiguration } from '../configuration.services';

@Injectable({
    providedIn: 'root',
})
export class ServerSentEventService {
    private _message: WritableSignal<string> = signal(null);
    private _generatingInProgress: WritableSignal<boolean> = signal(false);
    sseId: any = '0';
    ctrl = new AbortController();

    getSseId() {
        return this.sseId;
    }

    get generatingInProgress(): Signal<boolean> {
        return this._generatingInProgress.asReadonly();
    }

    closeConnection() {
        this.ctrl.abort();
    }

    get message(): Signal<string> {
        return this._message.asReadonly();
    }

    setMessage(msg: string) {
        this._message.set(msg);
    }

    saveButtonService = inject(SaveButtonService);
    _dialogService = inject(DialogBuilderService);
    _translocoService = inject(TranslocoService);

    executeActionSignal = signal<string>('');
    _translocoContent = toSignal(this._translocoService.selectTranslation());

    constructor(@Inject(APP_CONFIGURATION_TOKEN) private appConfig: FrontendAppConfiguration) {}

    async getServerSentEvents() {
        const url = `${this.appConfig.apiHost}/backend/api/sse`;

        let token = sessionStorage.getItem('access_token');

        if (!token) {
            token = localStorage.getItem('access_token');
        }

        this.sseId = uuidv4();

        try {
            await fetchEventSource(url, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-sse-id': this.sseId,
                },
                signal: this.ctrl.signal,
                openWhenHidden: true,

                onopen: async res => {
                    const contentType = res.headers.get('content-type');

                    if (!!contentType && contentType.indexOf('application/json') >= 0) {
                        throw await res.json();
                    }
                },
                onerror: e => {
                    if (!!e) {
                        console.error('Fetch onerror', e);
                    }

                    throw e;
                },
                onmessage: async ev => {
                    const data = ev.data;

                    if (data.includes('Generating task description')) {
                        this._generatingInProgress.set(true);
                    } else {
                        this._generatingInProgress.set(false);
                    }

                    this._message.set(data);
                },
            });
        } catch (e) {
            console.error('Error', e);
        }
    }

    showDialog(): Observable<boolean> {
        if (this._generatingInProgress()) {
            const dialogRef = this._dialogService.openDialog({
                descriptor: {
                    dialogSize: 's',
                    header: {
                        title: this._translocoContent()['confirmDialog.inProgressTitle'],
                        showCloseButton: false,
                    },
                    content: {
                        icon: {
                            show: true,
                            name: 'heroicons_outline:exclamation-triangle',
                            color: 'warn',
                        },
                        description:
                            this._translocoContent()['confirmDialog.unsavedChangesConfirmation'],
                    },
                    executeActionSignal: this.executeActionSignal,
                    actions: {
                        dialogActions: [
                            {
                                code: 'cancel',
                                style: ButtonType.flat,
                                title: this._translocoContent()['buttons.cancel'],
                                dismiss: true,
                            },
                            {
                                code: 'continue',
                                color: 'primary',
                                style: ButtonType.raised,
                                title: this._translocoContent()['buttons.yes'],
                            },
                        ],
                    },
                },
            });

            return dialogRef.afterClosed().pipe(
                map(value => {
                    return this.executeActionSignal() === 'continue';
                })
            );
        } else {
            return of(true);
        }
    }
}
